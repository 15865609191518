import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Menuicons } from '../Pages/Menuicons';
import { Loading } from '../Loading';
import Logo from '../Logo';


const Datavalidation = () => {
    const history = useNavigate();
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem('submittedData');
        const parsedData = JSON.parse(storedData);
       const City=parsedData.city;
       const Name=parsedData.name;
       const DOB=parsedData.dob;
       const Time=parsedData.time;
       const PhoneNumber=parsedData.phoneNumber
       const countryCode=parsedData.countryCode
       

      
        const response = await fetch(`https://api.vedicastroapi.com/v3-json/utilities/geo-search?city=${City}&api_key=1127d29b-aaef-5396-8d1c-69c5bcfeb584`);
        const data = await response.json();
        console.log(data)
        const NewData=data.response[0];
        console.log(NewData);
        const Latitude=NewData.coordinates[0];
        const Longitude=NewData.coordinates[1];
        const Timezone=NewData.tz;
        console.log(Latitude,Longitude,Timezone);
        const updatedData={
            name: Name,
            dob: DOB,
            time: Time,
            lat: Latitude,
            long: Longitude,
            timezone:Timezone,
            countryCode:countryCode,
            phoneNumber:PhoneNumber,
            city:City
          
          // Add more values as needed
        };
        console.log(Name,DOB,Time,Longitude,Latitude,Timezone)
        
        localStorage.setItem('submittedData', JSON.stringify(updatedData));
       
        // Redirect to the other page after fetching
     history('/masterdata_storing');
      } catch (error) {
        
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [history]);
  return (
    <>
    
    <Menuicons/>
    <Loading/>
    </>
    

  )
}

export default Datavalidation