import React from 'react';
import "./AsrLogo.scss";
import RishiLogo from "../Assets/astrorishi.png";
import  Chakra from "../Assets/astromain.png"


const AsroLogo = () => {
  return (
    <>
    <div className='main_astro_logo'>
        <div className='main_astro_logo_circle'> <img src={Chakra}/></div>
        <div className='main_astro_logo_rishi'> <img src={RishiLogo}/></div>
       


    </div>
   
    </>
  )
}

export default AsroLogo