import React, { useEffect, useState } from 'react';
import './FirstPage.scss';
import { Link, useNavigate } from 'react-router-dom';
import { IoDiamond } from "react-icons/io5";
import { FaSpinner } from "react-icons/fa";
import RishiLogo from "../../Assets/astrorishi.png";
import  Chakra from "../../Assets/astromain.png"


import { Menuicons } from '../Menuicons';

import Logo from "./ar.png";
import axios from "axios";
import AsroLogo from '../AsroLogo';


export const FirstPage = () => {

 
  const Navigate=useNavigate();
  const [IsReport, setIsReport] = useState(false)
  const [Loading, setLoading] = useState(false)
  const data=localStorage.getItem("CustomerTokenCredentials");

  
  const resolveData=async()=>{
    function fetchData() {
      const TokenData = localStorage.getItem('CustomerTokenCredentials');
      console.log(TokenData)
      const ParsedTokenData=JSON.parse(TokenData);
      const Token=ParsedTokenData.token
      return new Promise((resolve, reject) => {
        //
        fetch('https://9gems-backend-3tn45mros-danishsaghirdanfas-projects.vercel.app/users_verification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Response:', data);
          console.log("User successfully authenticated");
          resolve(data); // Resolve the promise with the response data
        })
        .catch(error => {
          console.error('Error:', error);
          reject(error); // Reject the promise with the error
        });
      });
      
    }
    
    try {
      const responseData = await fetchData();
     
    } catch (error) {
     console.log(error)
    }
    
  }
  const reportdata=localStorage.getItem('PostData');





  useEffect(() => {
    const data=localStorage.getItem("CustomerTokenCredentials");
    
  if(!data){
  Navigate('/customer_login')
  }
  
  resolveData();
  
  
  if(reportdata){
    setIsReport(true)
  }else{
    setIsReport(false)
  }
  
  
  
  
  }, [data,reportdata])
  


  const HandleNavigate=()=>{
    Navigate('/data')
  }
  const HandleNewNavigate=async()=>{
    setLoading(true)
    
    const FetchData= async ()=>{
      const TokenData = localStorage.getItem('CustomerTokenCredentials');
      const ParsedTokenData=JSON.parse(TokenData);
      const Token=ParsedTokenData.token
      const config = {
          headers: {
            
            'Authorization': `Bearer ${Token}`
            // Add any other headers you need
          }
        };
      await axios.post('https://9gems-backend-3tn45mros-danishsaghirdanfas-projects.vercel.app/users_verification', {},config)
  .then(response => {
  console.log('Response:', response.data);
  const Data= response.data.all_reports
  localStorage.setItem("Auth_reports_Data",JSON.stringify(Data));
  setLoading(false)
  })
  .catch(error => {
  console.error('Error:', error);
  });
   
   }
   await FetchData();
   setLoading(false)
  if(!Loading){
    Navigate('/all_reports')
  }else{
    setLoading(true)
    console.log("Loading")
  }
    
  }
 
  return (
   <div className='Dashboard'>
  
  
    <Menuicons/>

    <h4 className='Astroguru_heading_First_page'> Astro guru</h4>
    
     



<div className='Dashboard_circle playing'>
  
<div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div className='hero_main_astro_logo'>
        <div className='hero_main_astro_logo_circle'> <img src={Chakra}/></div>
        <div className='hero_main_astro_logo_rishi'> <img src={RishiLogo}/></div>
       


    </div>

</div>
<div>
<button  className='buttons' onClick={HandleNavigate}> Know Your Lucky Gemstone</button>
{IsReport?<button  className='buttons ' onClick={HandleNewNavigate}>{Loading?<FaSpinner className='loader'/>:' My Reports'  }</button>:<></>}
</div>
   
   
   
   </div>
  );
};
