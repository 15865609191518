import React, { useState, useEffect, Alert } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import './LoginPage.scss'; // Import SCSS file for styling
import LoginVideo from "./LoginBackground.mp4";

const LoginPage = () => {
  const navigate = useNavigate();
  const [videoLoaded, setVideoLoaded] = useState(false); // Track video loading status
  const [inputValues, setInputValues] = useState({
   PhoneNumber:''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
   PhoneNumber:false
  }); // Track form validation errors
  const [loginError, setLoginError] = useState(false); // Track login error

  useEffect(() => {
    // Add event listener to track video loading
    const handleVideoLoaded = () => {
      setVideoLoaded(true);
    };

    const videoElement = document.getElementById('loginVideo');
    if (videoElement) {
      videoElement.addEventListener('loadeddata', handleVideoLoaded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadeddata', handleVideoLoaded);
      }
    };
  }, []);

   useEffect(() => {
    const jwtToken = localStorage.getItem('Token');
    if (jwtToken) {
      navigate('/customer_login');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    // Form validation
    if (!inputValues.PhoneNumber ) {
      setFormErrors({
        PhoneNumber: !inputValues.PhoneNumber,
        
      }); // Set form validation errors
      return;
    }
  
    setIsLoading(true);
  
    try {
      const { PhoneNumber } = inputValues;
       

      const url = 'https://9gem.net/app/store-sign-in';
      const data = {
        // Your data object that you want to send in the POST request body
      };
      
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Assuming you're sending JSON data
          // Add any other headers you need
        },
        body: JSON.stringify({"phone":PhoneNumber}), // Convert data to JSON format
      };
      
      fetch(url, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Parse the JSON data
        })
        .then(data => {
          console.log(data);
          const Logindata={
            "userId":data.userId,
            "isPhone":data.isPhone,
            "phoneNumber":data.phone

          }
           localStorage.setItem('loginCredentials',JSON.stringify(Logindata))
           if(!data.userId){
            alert("Please Enter Valid Phone Number")
           }else{
            navigate('/otp-validation')
           }
          
        })
        .catch(error => {
          alert("Invalid Phone Number")
          console.error('There was a problem with the fetch operation:', error);
        });
      
  
  
    
      
      setIsLoading(false);
    
    } catch (error) {
      alert(error);
     

      console.error('Error:', error); // Handle error
      setIsLoading(false);
      setLoginError(true); // Set login error state
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name,value)
    setInputValues({
      ...inputValues,
      [name]: value
    });
    setFormErrors({
      ...formErrors,
      [name]: false
    }); // Clear form validation error when input changes
  };

  return (
    <div className={`login-page ${videoLoaded ? 'video-loaded' : ''}`}>
   <video id="loginVideo" src={LoginVideo} className='fullscreen-video' autoPlay muted />
      <motion.h4 className="page-title" initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
      <h4 className='Astroguru_Page_headings'> Astro guru</h4>
      </motion.h4>
      <div >
      <motion.div className="form-container"  initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.3 }}>
        <form onSubmit={handleSubmit} className="login-form">
          <label className="form-label" style={{textAlign:'center'}}>Manager Login</label>
          <motion.input
            placeholder="Enter Your Phone Number "
            name="PhoneNumber"
            value={inputValues.PhoneNumber}
            onChange={handleInputChange}
            className={`form-input ${formErrors.PhoneNumber? 'border-red-500' : ''}`} // Apply red border if store ID is empty
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          />
          {formErrors.PhoneNumber && (
            <p className="error text-red-500 text-xs mt-1">Please enter your PhoneNumber </p>
          )}

          
          
          
         
          {loginError && (
            <p className="error text-red-500 text-xs mt-1">Login error</p>
          )}
          <motion.button className="form-button" disabled={isLoading} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            {isLoading ? 'Loading...' : 'LOGIN'}
          </motion.button>
        </form>
       
      </motion.div>
      </div>
    </div>
  );
};

export default LoginPage;
