import  React from 'react';
import { IoHome } from "react-icons/io5";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { IoReload } from "react-icons/io5";
import "./Menuicon.scss";
import { IoIosLogOut } from "react-icons/io";



export const Menuicons = () => {
  const Navigate=useNavigate();
  
  const Logout=()=>{
    console.log('clicked')
    localStorage.removeItem('CustomerTokenCredentials');
    localStorage.removeItem('PostData');
    localStorage.removeItem('submittedData') 
    localStorage.removeItem('PreviousReport') 
   
    Navigate('/customer_login')
  }
  
    const goBack = () => {
      window.history.back();
    };
    const goForward = () => {
      window.history.forward();
    };
    const goHome = () => {
      Navigate('/')
    
    
    };

    const reloadPage = () => {
      window.location.reload();
    };

   
  return (
    <>
   <IoReload className='reload_icon' onClick={reloadPage}/>
        <IoHome className='home_icon' onClick={goHome}/>
        <IoIosLogOut  className='Logout_icon' onClick={Logout}/>
        </>
  )
}
