import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Menuicons } from '../Menuicons';
import './DataPage.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Video from "../../Assets/universeVideo.mp4";
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from "react-icons/io5";
import AstroLogoData from '../AstroLogoData';

const DataPage = () => {
  const Navigate = useNavigate();
  const [numberData, setNumberData] = useState({
    phoneNumber: '',
    countryCode: '',
  });
  const [inputValues, setInputValues] = useState({
    name: '',
    dob: '',
    time: '',
    city: '',
    phoneNumber: numberData.phoneNumber,
    countryCode: numberData.countryCode,
    weight: ''
  });

  const [valid, setValid] = useState(true);

  useEffect(() => {
    const data = localStorage.getItem("CustomerTokenCredentials");
    if (!data) {
      Navigate('/customer_login');
    }

    const synthesis = window.speechSynthesis;
    const customScript = "I can help you to discover your lucky gemstone"; 
    const utterance = new SpeechSynthesisUtterance(customScript);
    synthesis.speak(utterance);
  }, [Navigate]);

  const handleChange = (e, name, value) => {
    if (name === "phoneNumber") {
      let phoneNumber = e;
      setNumberData({
        ...numberData,
        phoneNumber: phoneNumber
      });
    } else {
      setNumberData({ ...numberData, [name]: e.target.value });
    }
    setValid(validatePhoneNumber(value));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const weightValue = parseFloat(inputValues.weight);
    const weightDivided = weightValue / 10;
    const weightFormatted = Number.isInteger(weightDivided) ? 
      weightDivided.toString() : 
      `${Math.floor(weightDivided)}+`;
  
    const dataToSave = {
      ...inputValues,
      phoneNumber: numberData.phoneNumber,
      countryCode: numberData.countryCode,
    };
  
    localStorage.setItem('submittedData', JSON.stringify(dataToSave));
    localStorage.setItem('weightData', weightFormatted); // Save formatted weight separately
    Navigate('/DataValidate');
  };
  

  return (
    <>
      <Menuicons />
      <div className="navigation-icons">
        <IoChevronBackCircleOutline 
          className='nav-icon'  
          onClick={() => {window.history.back();}}
        />
        <IoChevronForwardCircleOutline 
          className='nav-icon' 
          onClick={() => {window.history.forward();}}
        />
      </div>
      <h4 className='Astroguru_heading_Data_Page'>Astro guru</h4>
      <section style={{ height: '100vh', overflow: 'hidden' }}>
        <video src={Video} autoPlay muted loop className="fullscreen-video" />
        <div className="datapage" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <div className="DataPage_Form" style={{ width: '100%', maxWidth: '700px', padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '15px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)' }}>
            <form onSubmit={handleSubmit} className="form-data grid-form" >
              <div className="grid-container">
                <div className="grid-item">
                  <label htmlFor="name" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Name</label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Customer Name"
                    value={inputValues.name}
                    onChange={handleInputChange}
                    required
                    className='input'
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="phoneNumber" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Mobile Number</label>
                  <PhoneInput
                    required
                    className='input'
                    name="phoneNumber"
                    country={'in'}
                    value={numberData.phoneNumber}
                    onChange={(e, phone) => handleChange(e, "phoneNumber", phone)}
                    inputStyle={{
                      backgroundColor: '#333',
                      color: '#fff',
                      paddingLeft: '45px',
                      fontSize: '14px',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="dob" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Date Of Birth</label>
                  <input
                    id="dob"
                    type="date"
                    name="dob"
                    value={inputValues.dob}
                    onChange={handleInputChange}
                    required
                    className="custom-input"
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="time" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Time Of Birth</label>
                  <input
                    id="time"
                    type="time"
                    name="time"
                    value={inputValues.time}
                    onChange={handleInputChange}
                    required
                    className="custom-input"
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="city" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold' }}>Place Of Birth</label>
                  <input
                    id="city"
                    type="text"
                    name="city"
                    placeholder="Customer's City of Birth"
                    value={inputValues.city}
                    onChange={handleInputChange}
                    required
                    className="custom-input"
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
                <div className="grid-item">
                  <label htmlFor="weight" style={{ color: '#fff', marginBottom: '5px', fontWeight: 'bold',fontFamily: 'sans-serif' }}>Weight (In Kg)</label>
                  <input
                    id="weight"
                    type="number"
                    name="weight"
                    placeholder="Customer's Weight"
                    value={inputValues.weight}
                    onChange={handleInputChange}
                    required
                    className="custom-input"
                    style={{
                      width: '100%',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '15px',
                      backgroundColor: '#333',
                      color: '#fff',
                      fontSize: '14px',
                      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type="submit"
                className="submit-btn"
                style={{
                  gridColumn: '1 / -1',
                  padding: '15px',
                  border: 'none',
                  borderRadius: '25px',
                  backgroundColor: '#ff6347',
                  color: '#fff',
                  fontSize: '18px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  boxShadow: '0 0 10px rgba(255, 99, 71, 0.5)',
                  marginTop: '20px',
                }}
              >
                Submit
              </motion.button>
            </form>
          </div>
        </div>
      </section>
      <AstroLogoData />
    </>
  );
};

export default DataPage;
