import React from 'react';
import "./NewReport.scss";
import { IoDiamond } from "react-icons/io5";

const NewReport = () => {
  return (
    
<div className='Report'>
<h2 className='heading_customer_Dashboard'> My Lucky Gems <IoDiamond /></h2>
<div className="form-container">
      <form className="form">
       

        <div className="form-group">
          <label for="email">Name</label>
          <input required="" name="name" id="name" type="text"/>
       
        <div className="form-group">
          <label for="email">Date Of Birth</label>
          <input required="" name="date" id="date" type="date"/>
        </div>
        </div>
      
        <div className="form-group">
          <label for="email">Time Of Birth</label>
          <input required="" name="time" id="time" type="time"/>
        </div>
        <div className="form-group">
          <label for="email">City</label>
          <input required="" name="city" id="city" type="text"/>
        </div>
        
        <button type="submit" className="form-submit-btn">Submit</button>
      </form>
    </div>
    </div>
      
    
  )
}

export default NewReport