import React from 'react';

import "./Loading.scss";
import AstroLogoData from "./Pages/AstroLogoData"

export const Loading = () => {
  return (
    <div className='loading_div'>
   <AstroLogoData/>
   <h4 className='Astroguru_headings'> AStro guru</h4>
   </div>
  )
}
