
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import motion from Framer Motion
import './LoginPage.scss'; // Import SCSS file for styling
import LoginVideo from "./LoginBackground.mp4";



const OTP = () => {
  const navigate=useNavigate();
    const [OTP, setOTP] = useState('');
    const [userId, setUserId] = useState('');
    const [isPhone, setisPhone] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const [formErrors, setFormErrors] = useState({
        OTP:false
       });

       useEffect(() => {
        const data = localStorage.getItem('loginCredentials');
        if(data){
            const updatedData=JSON.parse(data);
           const userID=updatedData.userId
           setUserId(userID)
           setisPhone(updatedData.isPhone)
           
        }
       
      
      }, []);
    
       const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
      
        // Form validation
        if (!OTP ) {
          setFormErrors({
           OTP: !OTP,
            
          }); // Set form validation errors
          return;
        }
      
     
      
        try {
          
           
      
          const url = 'https://9gem.net/app/store-login-with-otp';
         const data={
            "userId":userId,
            "isPhone":isPhone,
            "otp":OTP

         }
        
          const requestOptions = {
           
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Assuming you're sending JSON data
              // Add any other headers you need
            },
            body: JSON.stringify(data) 

            // Convert data to JSON format
          };
          
          fetch(url, requestOptions)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json(); // Parse the JSON data
            })
            .then(data => {
              console.log(data);
              if(data.user.id){

                localStorage.setItem('Token',data.token)
                navigate('/customer_login')
              }
              else{
                alert("invalid otp")
              }

             
             
             
               
            })
            .catch(error => {
              alert("invalid otp")
              console.error('There was a problem with the fetch operation:', error);
            });
          
      
      
         /* const data = await response.json();
          const jwtToken = data.message.jwt_token;
          if (jwtToken) {
           localStorage.setItem('jwtToken', jwtToken);
            localStorage.setItem('phoneNumber',PhoneNumber);
          }*/
      
          // Handle successful login response
          setIsLoading(false);
        
        } catch (error) {
          console.error('Error:', error); // Handle error
          setIsLoading(false);
          setLoginError(true); // Set login error state
        }
      };
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(name,value)
        setOTP(value)
        setFormErrors({
          ...formErrors,
          [name]: false
        }); // Clear form validation error when input changes
      };
    
  return (
    <>
     <video id="loginVideo" src={LoginVideo} className='fullscreen-video' autoPlay muted />
    <motion.div className="form-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.3 }}>
        <form onSubmit={handleSubmit} className="login-form">
          <label className="form-label" style={{textAlign:"center"}}>OTP</label>
          <motion.input
            placeholder="Enter Your OTP "
            name="OTP"
            value={OTP}
            onChange={handleInputChange}
            className={`form-input ${formErrors.OTP? 'border-red-500' : ''}`} // Apply red border if store ID is empty
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          />
          {formErrors.PhoneNumber && (
            <p className="error text-red-500 text-xs mt-1">Invalid OTP </p>
          )}

          
          
          
         
          {loginError && (
            <p className="error text-red-500 text-xs mt-1">Login error</p>
          )}
          <motion.button className="form-button" disabled={isLoading} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            {isLoading ? 'Loading...' : 'LOGIN'}
          </motion.button>
        </form>
       
      </motion.div>
      </>
  )
}

export default OTP