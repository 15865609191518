import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import "./ProtectedRoute.scss";
import { Loading } from './Loading';
import VideoLayout from './VideoLayout';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const token = localStorage.getItem('Token');
        if (token && token.trim() !== '') {
          setIsAuthenticated(true)
        } else {
          setIsAuthenticated(false) // No token found
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsAuthenticated(false) // Error occurred
      } finally {
        setIsLoading(false); // Set loading to false regardless of the outcome
      }
    };
    

    verifyToken();

    const handleStorageChange = (event) => {
      if (event.key === 'Token') {
        if (!event.newValue) {
          // Token is removed from local storage, logout the user
          setIsAuthenticated(false);
        } else if (!isAuthenticated) {
          // Token is added or changed, verify and set isAuthenticated if valid
          verifyToken();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isAuthenticated]);

  if (isLoading) {
    return (
      <VideoLayout>
        <div className="loading-spinner">
          <Loading />
        </div>
      </VideoLayout>
    );
  }

  return isAuthenticated ? <Outlet /> : navigate("/login");
};

export default ProtectedRoute;
