// src/components/Pagination.js
import React from 'react';
import "./Pagination.scss";
import { IoArrowBackCircle } from 'react-icons/io5';
import { IoArrowForwardCircle } from 'react-icons/io5';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    onPageChange(page);
  };

  return (
  
    <div className="pagination">
      <button 
        onClick={() => handlePageChange(currentPage - 1)} 
        disabled={currentPage === 1}
      >
           <IoArrowBackCircle  className='Arrow_design_back'/>

      </button>
   
      <button 
        onClick={() => handlePageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
      >
            <IoArrowForwardCircle  className='Arrow_design_Forward'/>

      </button>
    </div>
  );
};

export default Pagination;
