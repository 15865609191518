import React from 'react';
import "./AstroLogoData.scss";
import RishiLogo from "../Assets/astrorishi.png";
import  Chakra from "../Assets/astromain.png"


const AstroLogoData= () => {
  return (
    <>
    <div className='main_astro_logos'>
        <div className='main_astro_logo_circles'> <img src={Chakra}/></div>
        <div className='main_astro_logo_rishis'> <img src={RishiLogo}/></div>
       


    </div>
   
    </>
  )
}

export default AstroLogoData