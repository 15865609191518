import {React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Menuicons } from '../Pages/Menuicons';
import { Loading } from '../Loading';
import Logo from '../Logo';


const Datastoring = () => {
const Navigate=useNavigate();
useEffect(() => {

const fetchData=async()=>{

  try{     

    const storedData = localStorage.getItem('submittedData');
    const TokenData = localStorage.getItem('CustomerTokenCredentials');
    console.log(TokenData)
    const parsedData = JSON.parse(storedData);
    const ParsedTokenData=JSON.parse(TokenData);
    console.log(ParsedTokenData)
   const City=parsedData.city;
   const Name=parsedData.name;
   const DOB=parsedData.dob;
   const Time=parsedData.time;
   const PhoneNumber=parsedData.phoneNumber
   const latitude=parsedData.lat
   const longitude=parsedData.long
   const countryCode=parsedData.countryCode
   const Token=ParsedTokenData.token
   const masterUserId=ParsedTokenData.masterUserId
console.log(latitude,longitude,Token)
   const PrevData=localStorage.getItem("PostData");
   console.log(parsedData)

if(!PrevData){

  const url = 'https://9gems-backend-3tn45mros-danishsaghirdanfas-projects.vercel.app/users_master_data_storing';
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Token}`
    },
    body: JSON.stringify({
      "masterUserId": masterUserId,
      "name":Name,
      "gender":"male",
      "email":"dawinder@gmail.com",
      "date_of_birth":DOB,
      "time_of_birth":Time,
      "latitude":latitude,
      "longitude":longitude,
      "city":City,
      "whats_app":PhoneNumber
     

    }), 
  // Convert data to JSON format
  };
const fetchFunction=async ()=>{
  await fetch(url, requestOptions)
  .then(response => {
    return response.json(); // Parse the JSON data
  })
  .then(data => {
    console.log(data);
  Navigate("/gemstoneValidate")


  })
  .catch(error => {

    console.error('There was a problem with the fetch operation:', error);
  });
 
}

fetchFunction();



}else{
  Navigate("/gemstoneValidate")
}
    
   
    } catch (error) {
        
        console.error('Error fetching data:', error);
      }

}

   
fetchData();

}, [])



  return (
    <>
    <Logo/>
    <Menuicons/>
    <Loading/>
    </>
    
  )
}

export default Datastoring