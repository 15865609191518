import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosLogOut } from "react-icons/io";
import "./animate.css";
import "./main.css";
import "./ShowData.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Slide from "./img/bg-slide.png"
import shape6 from "./img/shape6.png"
import twitter from "./img/twitter-icon.png"
import ribbon1 from "./img/ribbon-1.png"
import ribbon2 from "./img/ribbon-2.png"
import { useNavigate } from 'react-router-dom';
import blueSapphire from "../../Assets/bluesapphire.png";
import Emerald from "../../Assets/emerald.png";
import pearl from "../../Assets/pearl.png"
import redCoral from "../../Assets/redcoral.png";
import Ruby from "../../Assets/ruby.png";
import yellowSapphire from "../../Assets/yellowsapphire.png";
import whiteSapphire from "../../Assets/whiteSapphire.png";
import { CgProfile } from "react-icons/cg";
import { FaArrowTrendUp, FaRegGem, FaWeibo } from "react-icons/fa6";
import html2pdf from 'html2pdf.js';
import { BiTime, BiWorld } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { MdOutlinePlace } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { FaGem } from "react-icons/fa";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { DiMongodb } from "react-icons/di";
import { IoLogoDribbble } from "react-icons/io";
import { IoMdHappy } from "react-icons/io";
import { FaHandPaper } from "react-icons/fa";
import { CiCalendar } from "react-icons/ci";
import { FaHandPointer } from "react-icons/fa";
import { PiDiamondsFour } from "react-icons/pi";
import { FaWeight } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import axios from "axios";
import { Menuicons } from '../Menuicons';
import { IoHome } from "react-icons/io5";
import { IoReload } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import QRCode from 'qrcode.react';


function YourResult() {
    const printRef = useRef();
    const Navigate = useNavigate();
    const TokenData = localStorage.getItem('CustomerTokenCredentials');
    const ParsedTokenData = JSON.parse(TokenData);
    const Token = ParsedTokenData.Token
    const masterUserId = ParsedTokenData.masterUserId;
    const [Weight, setWeight] = useState('')
    const [userName, setUserName] = useState('');
    const [gemsName, setGemsName] = useState('');
 
    const [description, setDescription] = useState('');
    const [day, setDay] = useState('');
    const [ascendantSign, setAscendantSign] = useState('');
  
    const [Metal, setMetal] = useState('');
    const [Finger, setFinger] = useState('');
    const [OtherName, setOtherName] = useState('');
    const [TimeToWear, setTimeToWear] = useState('')
    const [City, setCity] = useState("")
    const [Dob, setDob] = useState('')
    const [Tob, setTob] = useState('')
    const [Latitude, setLatitude] = useState('');
    const [Longitude, setLongitude] = useState('');
    const [countryCode, setcountryCode] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [Mantra, setMantra] = useState('');
    const [Methods, setMethods] = useState('');
    const [Planet, setPlanet] = useState('')
    const [Logo, setLogo] = useState(blueSapphire)
    const [Style, setStyle] = useState('')
    const [Color, setColor] = useState('')
    const [RibbonColor, setRibbonColor] = useState('');
    const [ReportData, setReportData] = useState({});
    const [GoodResults, setGoodResults] = useState('');
    const [substitute, setSubstitute] = useState('')
    const [pdfUrl, setPdfUrl] = useState(null);



    useEffect(() => {


        const data = localStorage.getItem("CustomerTokenCredentials");
        const Weight = localStorage.getItem("weightData");
        setWeight(Weight)

        if (!data) {
                Navigate('/customer_login')
        }
fetchUserData();
fetchUserReportData();
        const synthesis = window.speechSynthesis;
                const customScript = " Congratulations Here is your Lucky Gemstone  "; 
                const utterance = new SpeechSynthesisUtterance(customScript);
                synthesis.speak(utterance)
       handleGeneratePdf();



    }, []);

   
    const handleGeneratePdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element, {
          scrollY: -window.scrollY,
          useCORS: true,
        });
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        const totalPages = Math.ceil(imgProperties.height / canvas.height);
    
        for (let i = 0; i < totalPages; i++) {
          const startY = i * canvas.height;
          pdf.addImage(data, 'PNG', 0, -startY, pdfWidth, pdfHeight);
          if (i < totalPages - 1) {
            pdf.addPage();
          }
        }
    
        // Create a blob URL for the PDF and set it to state
       
      };
    
    

    const goBack = () => {
        window.history.back();
    };
    const goForward = () => {
        window.history.forward();
    };

    const reloadPage = () => {
        window.location.reload();
    };
    const formatDate = (dateString) => {
        // Parse the input date string into a Date object
        const date = new Date(dateString);

        // Extract day, month, and year components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1
        const year = date.getFullYear();

        // Format day and month components to ensure leading zeros if needed
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Construct the formatted date string in "DD-MM-YYYY" format
        const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

        return formattedDate;
    };

    const convertTo12HourFormat = (time24) => {
        // Split the time into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);

        // Convert hours to 12-hour format
        const hours12 = hours % 12 || 12;

        // Determine AM or PM
        const period = hours < 12 ? 'AM' : 'PM';

        // Construct the formatted time string
        const time12 = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;


        return time12;
    };

    const downloadAsPdf = () => {
        const element = document.querySelector('.gemstone_table');


        html2pdf().from(element).save('data.pdf');
    };


    const fetchUserData = async () => {
        const storedData = localStorage.getItem('submittedData');
        const userData = JSON.parse(storedData);
        const CustomerCity=userData.city
        const gemData = userData.GemstoneData;
        const name = userData.name;
        const upperCaseName = name.toUpperCase();
        const parts = userData.dob;
        const DataDOB = formatDate(parts)
        const Time = userData.time
        const formattedTime = convertTo12HourFormat(Time);
        setTob(formattedTime);
        const lat = userData.lat;
        const long = userData.long;
        const countryCodeData = userData.countryCode;
        const phoneNumberData = userData.phoneNumber;
        console.log(userData.phoneNumber)

        setcountryCode(countryCodeData);
        setPhoneNumber(phoneNumberData);
setCity(CustomerCity)
        setLatitude(lat);
        setLongitude(long);

        setDob(DataDOB);
        setUserName(upperCaseName);
        const ascendantData = userData.Ascendant;
        var convertedGemData = gemData.toLowerCase().replace(/\s/g, '_');
        console.log(convertedGemData)

        if (convertedGemData === 'red_coral') {
            console.log("I am inside Coral sector ")
            convertedGemData = 'coral';
        }

        if (convertedGemData === 'diamond') {
            setDay('Friday');
            setDescription('The white sapphire gemstone can help to bring enormous peace, joy, and tranquility to the wearer. The white Sapphire gemstone has a beneficial effect on the procreative organs functions. It can also aid in the diagnosis and healing of contagious diseases in the medical field');
            setSubstitute('diamond');
            setGemsName('White Sapphire');
            setTimeToWear('first hour from the sunrise');
            setGoodResults("Increases immunity ,Improves creativity,Bestow with wealth and status");
            setFinger("Ring Finger");
            setMetal("Silver or Platinum");
            
            setOtherName("Safed Pukhraj ")
            setMantra("Om Shum Shukraye Namah")
            setMethods("It should be worn on the middle finger or on the ring finger of your right hand")

            setAscendantSign(ascendantData);

            console.log("I am inside Diamond sector ")
            const UpdatedData = {
                "userName": name,
                "gemsName":"White Sapphire" ,
                "substitute": "diamond",
                "description":'The white sapphire gemstone can help to bring enormous peace, joy, and tranquility to the wearer. The white Sapphire gemstone has a beneficial effect on the procreative organs functions. It can also aid in the diagnosis and healing of contagious diseases in the medical field',
                "day": "Friday",
                "ascendantSign": ascendantData,
                "GoodResults":"Increases immunity ,Improves creativity,Bestow with wealth and status",
                "Metal": "Silver or Platinum",
                "Finger": "Ring Finger",
                "OtherName": "Safed Pukhraj ",
                "TimeToWear": "first hour from the sunrise",
                "Weight": "5 carats or 1/12th of the body weight or more",
                "Dob": DataDOB,
                "Tob": Time,
                "Latitude": lat,
                "Longitude": long,
                "countryCode": countryCodeData,
                "PhoneNumber": phoneNumberData,
                "Mantra":"Om Shum Shukraye Namah",
                "Methods": "It should be worn on the middle finger or on the ring finger of your right hand"
            };

            console.log(UpdatedData);
            const reportdata = localStorage.getItem('PostData');
            if(reportdata){

                const ParseData = JSON.parse(reportdata);
                console.log('before push', ParseData);
                ParseData.push(UpdatedData);
                
            console.log('after push', ParseData);
            localStorage.setItem("PostData", JSON.stringify(ParseData));
            }
            else{
                const ArrayData=[UpdatedData]
                localStorage.setItem("PostData", JSON.stringify(ArrayData));
            }





            const Data = localStorage.getItem("PostData");
            console.log('This is Data',Data)
            const all_reports = JSON.parse(Data);
            console.log("This is ParsedData:", all_reports)

            const TokenData = localStorage.getItem('CustomerTokenCredentials');
            const ParsedTokenData = JSON.parse(TokenData);
            const Token = ParsedTokenData.token
            console.log(Token)
            const url = 'https://9gems-backend-3tn45mros-danishsaghirdanfas-projects.vercel.app/user_data_storage';
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Token}`
                },
                body: JSON.stringify({
                    "masterUserId": masterUserId,
                    "all_reports": all_reports
                })


                // Convert data to JSON format
            };

            await fetch(url, requestOptions)
                .then(response => {
                    return response.json(); // Parse the JSON data
                })
                .then(data => {
                    console.log(data);



                })
                .catch(error => {

                    console.error('There was a problem with the fetch operation:', error);
                });
            // This will log each element of the array to the console









        } else {
            console.log("I am inside Other sector ")
           fetch(`https://api.vedicastroapi.com/v3-json/utilities/gem-details?api_key=1127d29b-aaef-5396-8d1c-69c5bcfeb584&gem=${convertedGemData}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }
                    return response.json();
                })
                .then(data => {


                    console.log(data)
                    setDay(data.response.response.day);
                    setDescription(data.response.response.description);
                    setSubstitute(data.response.response.substitute);
                    setGemsName(data.response.response.name);
                    setAscendantSign(ascendantData);
                    setGoodResults(data.response.response.good_results)
                    setMetal(data.response.response.metal)
                    setFinger(data.response.response.finger)
                    setTimeToWear(data.response.response.time_to_wear_short)
                    setOtherName(data.response.response.other_name)
                  
                    setMantra(data.response.response.mantra)
                    setMethods(data.response.response.methods)
                    setPlanet(data.response.response.planet)


                    const UpdatedData = {
                        "userName": name,
                        "gemsName": data.response.response.name,
                        "substitute": data.response.response.substitute,
                        "description": data.response.response.description,
                        "day": data.response.response.day,
                        "ascendantSign": ascendantData,
                        "GoodResults": data.response.response.good_results,
                        "Metal": data.response.response.metal,
                        "Finger": data.response.response.finger,
                        "OtherName": data.response.response.other_name,
                        "TimeToWear": data.response.response.time_to_wear_short,
                        "Weight": data.response.response.weight,
                        "Dob": DataDOB,
                        "Tob": Time,
                        "Latitude": lat,
                        "Longitude": long,
                        "countryCode": countryCodeData,
                        "PhoneNumber": phoneNumberData,
                        "Mantra": data.response.response.mantra,
                        "Methods": data.response.response.methods
                    };

                    setReportData(UpdatedData)

                    const reportdata = localStorage.getItem('PostData');
                    if(reportdata){

                        const ParseData = JSON.parse(reportdata);
                        console.log('before push', ParseData);
                        ParseData.push(UpdatedData);
                        
                    console.log('after push', ParseData);
                    localStorage.setItem("PostData", JSON.stringify(ParseData));
                    }
                    else{
                        const ArrayData=[UpdatedData]
                        localStorage.setItem("PostData", JSON.stringify(ArrayData));
                    }

                    









                    if (data.response.response.name === "Yellow Sapphire") {
                        setLogo(yellowSapphire)
                        setStyle("#FF9800")
                        setRibbonColor("#FFD200")

                    }

                    if (data.response.response.name === "Red Coral") {
                        setLogo(redCoral)
                        setStyle("#BF3131")
                        setColor("WHITE")
                        setRibbonColor("RED")
                    }


                    if (data.response.response.name === "Pearl") {
                        setLogo(pearl)
                        setStyle("#EAD196")
                        setColor("BLACK")
                        setRibbonColor("GREY")

                    }

                    if (data.response.response.name === "Diamond") {
                        setLogo(whiteSapphire)
                        setStyle("#EAD196")
                        setColor("BLACK")
                        setRibbonColor("GREY")

                    }


                    if (data.response.response.name === "Ruby") {
                        setLogo(Ruby)
                        setStyle("#A34343")
                        setColor("WHITE")
                        setRibbonColor("RED")

                    }



                    if (data.response.response.name === "Emerald") {
                        setLogo(Emerald)
                        setStyle("#2C7865")
                        setColor("WHITE")
                        setRibbonColor("#77B0AA")

                    }
                    //
                    if (data.response.response.name === "Blue Sapphire") {
                        setLogo(blueSapphire)
                        setStyle("#074173")
                        setColor("WHITE")
                        setRibbonColor("BLUE")

                    }



                })
                .catch(error => {
                    console.log(error)
                });

                
               




        }
    };

    const fetchUserReportData=async()=>{
        const GetPushedData =  localStorage.getItem("PostData");
        const all_reports = JSON.parse(GetPushedData);
        console.log(all_reports)
        console.log("This is ParsedData:", all_reports)
    
        const TokenData = localStorage.getItem('CustomerTokenCredentials');
        const ParsedTokenData = JSON.parse(TokenData);
        const Token = ParsedTokenData.token
        console.log(Token)
        const url = 'https://9gems-backend-3tn45mros-danishsaghirdanfas-projects.vercel.app/user_data_storage';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Token}`
            },
            body: JSON.stringify({
                "masterUserId": masterUserId,
                "all_reports": all_reports
            })
        };
    
       await  fetch(url, requestOptions)
            .then(response => {
                return response.json(); // Parse the JSON data
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => {
    
                console.error('There was a problem with the fetch operation:', error);
            });
        // This will log each element of the array to the console
    
    
    
    }
    const handleScroll = () => {
        document.querySelector('.detail-wrap').scrollIntoView({ behavior: 'smooth' });
    };

    const Logout=()=>{
      
        localStorage.removeItem('data')
        localStorage.removeItem('CustomerTokenCredentials');
        localStorage.removeItem('PostData');
        localStorage.removeItem('submittedData') 
        localStorage.removeItem('PreviousReport') 
       
        Navigate('/customer_login')
      }
      
    const goHome = () => {
        Navigate('/')


    };




    return (<>

        <div ref={printRef}>
            <IoIosLogOut className='Logout_icon' onClick={Logout} />
            <IoHome className='home_icon' onClick={goHome} />
            <IoReload className='reload_icon' onClick={reloadPage} />
            <IoArrowBackCircle className='back_icons'  style={{cursor:"pointer",zIndex:"10"}} onClick={()=>{window.history.back();}}/>

            <header>
                <section className="slider-wrapper">
                    <div className="top-bar py-3 py-sm-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="position-relative wow fadeInUp" data-wow-delay=".1s">
                                        <div className="homeBtm"><a href="/"><i className="fas fa-home-lg-alt text-white" /></a></div>
                                        <div className=" text-center">
                                               <h4 className='Astroguru_Page_headings'> Astro guru</h4>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide-text py-5">
                        <div className="container">
                            <div className="row">
                                <div className="text-center mb-3 mb-sm-5 wow fadeInUp" data-wow-delay=".3s">
                                    <h1 className="d-flex align-items-center justify-content-center gap-3">
                                        <span className="d-none d-sm-block"><img src={ribbon1} alt="ribbon-1" /></span>
                                        <span className="congratsText letterSpacing2 text-uppercase font-60 font-weight-300 text-white d-inline-block rounded px-3 letterSpacing2">Congratulations</span>
                                        <span className="d-none d-sm-block"><img src={ribbon2} alt="ribbon-2" /></span>
                                    </h1>
                                    <h2 className=" font-50 font-weight-600 text-uppercase mb-3" style={{ color: RibbonColor }}>{userName}</h2>
                                    <h3 className="text-white font-30 mb-3">Your Lucky Gemstone is</h3>
                                    <h4 className="text-white font-50 font-weight-600">{gemsName}</h4>
                                </div>
                                <div className="d-flex d-sm-none justify-content-center mb-4 mb-sm-0">
                                    <div className="gemsstoneImageMobile" style={{ backgroundColor: RibbonColor }}>

                                        <img src={Logo} alt="yellow-sapphire" />
                                    </div>
                                </div>
                                <div className="scroll-down wow fadeInUp" data-wow-delay=".5s">
                                    <a href="#detail-wrap" id="scrollbtna" className="scroll-down-link text-center hoveroff" onClick={handleScroll}>
                                        <div className="scroll-title">Scroll Down</div>
                                        <div className="scroll-mouse">
                                            <div className="whell"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>


            <section className="detail-section" style={{fontFamily:'sans-serif'}}>
                <span className="srMarkshape8 position-absolute">
                    <img className="img-fluid" src={shape6} alt="shape6" />
                </span>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="detail-wrap">
                                <div className="gemsstoneImage d-none d-sm-block" style={{ backgroundColor: RibbonColor }} >
                                    <img src={Logo} alt="yellow-sapphire" style={{ width: "230px" }} />
                                </div>
                                <h2 className="mainHeading wow fadeInUp" data-wow-delay=".1s">
                                    <span className="line d-none d-sm-inline-block"></span>
                                    <span className="textHeading font-40 " style={{ color: RibbonColor }}>Lucky Gemstone Report</span>
                                </h2>
                                <div className="mt-3 mt-lg-5 pt-0 pt-sm-5 wow fadeInUp" data-wow-delay=".3s">
                                    <div className="row">
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <CgProfile style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Name</h3>
                                                    <p className="text-uppercase font-18 font-weight-700">{userName}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <FaArrowTrendUp style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Ascendant</h3>
                                                    <p className="text-uppercase font-18 font-weight-700">{ascendantSign}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <BiWorld style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Planet</h3>
                                                    <p className="text-uppercase font-18 font-weight-700">{Planet}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <FaPhoneAlt style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Phone </h3>
                                                    <p className="text-uppercase font-18 font-weight-700">+91 {PhoneNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <MdDateRange style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Date of Birth</h3>
                                                    <p className="text-uppercase font-18 font-weight-700">{Dob}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <IoMdTime style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Time of Birth</h3>
                                                    <p className="text-uppercase font-18 font-weight-700"> {Tob}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                            <div className="userDetail mt-3">
                                                <MdOutlinePlace style={{ fontSize: "30px" }} />
                                                <div className="ms-3">
                                                    <h3 className="text-uppercase text-muted font-16">Place of Birth</h3>
                                                    <p className="text-uppercase font-18 font-weight-700">{City}</p>
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="gemstoneDetail mt-5 wow fadeInUp" data-wow-delay=".5s">
                                    <ul className="list-unstyled">
                                        <li className="gemHeading  text-center font-18 font-weight-700 text-uppercase " style={{ backgroundColor: RibbonColor , fontFamily:'sans-serif'}}>
                                            Gemstone Detail</li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <FaGem style={{ color: "RED", fontSize: "20px" }} />   Gemstone Name
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{gemsName}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <FaGem style={{ color: "RED", fontSize: "20px" }} /> Hindi Name
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{OtherName}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5" >
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <MdDriveFileRenameOutline style={{ color: "RED", fontSize: "20px" }} /> Mantra
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{Mantra}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <IoMdHappy style={{ color: "RED", fontSize: "20px" }} />Benefits To Wear
                                                </h6>
                                            {  GoodResults?  <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}> {GoodResults.map((item, index) => <p style={{fontFamily:'sans-serif'}} key={index}>{item}</p>)}</p> : <p> Loading...</p>}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <FaHandPaper style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} />Methods To Wear
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}> {Methods}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <MdDateRange style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} />Best Day To Wear
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{day}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <BiTime style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} />Best Time To Wear
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{TimeToWear}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <FaHandPointer style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} />Finger To Wear
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{Finger}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <PiDiamondsFour style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} /> Best Metal To Wearing
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{Metal}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <FaRegGem style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} />Substitute
                                                </h6>
                                              {substitute?  <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{substitute.map((item) => <p style={{fontFamily:'sans-serif'}}>{item}</p>)}</p> :<p> Loading...</p>}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="innerText d-block d-sm-flex align-items-center gap-5">
                                                <h6 className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>
                                                    <FaWeight style={{ color: "RED", fontSize: "20px", marginRight: "5px" }} /> Gemstone Weight 
                                                </h6>
                                                <p className="font-18 font-weight-500 mb-0" style={{fontFamily:'sans-serif'}}>{Weight} Ratti</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="downloadBtn mt-3 mt-sm-5 pt-10 pb-10 pl-10 pr-10 text-center wow fadeInUp" data-wow-delay=".7s">
                                  



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <section className="upTextFooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="wow fadeInUp" data-wow-delay=".3s">
                                    <div className="textBigg poppinsBold text-center w-100"><span>Astro Guru</span></div>
                                </div>
                                <div className="footerContant wow fadeInUp" data-wow-delay=".3s">
                                    <div className="socialMediaFooter">
                                        
                                    </div>
                                    <p className="text-muted mb-5">© 2024 Copyright - <span className="textyellow cursor-pointer">Astro Guru</span> All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        </div>
    </>
    );
}

export default YourResult;
