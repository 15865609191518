// VideoLayout.js
import React from 'react';
import Video from "./Assets/universeVideo.mp4";
import './VideoLayout.scss'; // Import the CSS file instead of SCSS

const VideoLayout = ({ children }) => {

 
  return (
    <div className="video-container"> {/* Use className instead of class */}
      <video src={Video} autoPlay muted loop className="fullscreen-video" /> {/* Add className for styling */}
      <div className="content">{children}</div> {/* Add className for styling */}
    </div>
  );
};

export default VideoLayout;
