import React, { useEffect, useState } from 'react';
import './ReportPage.scss';
import { useNavigate } from 'react-router-dom';
import { IoReload, IoHome, IoArrowBackCircle } from "react-icons/io5";
import Pagination from './Pagination';


const ReportPage = () => {
  const Navigate = useNavigate();
  const [Data, setData] = useState({});
  const [ArrayData, setArrayData] = useState('');
  const [userName, setuserName] = useState('');
  const [ascendantSign, setAscendantSign] = useState('');
  const [Index, setIndex] = useState({ "value": 0 });
  const [gemsName, setGemsName] = useState('');
  const [substitute, setSubstitute] = useState('');
  const [description, setDescription] = useState('');
  const [day, setDay] = useState('');
  const [GoodResults, setGoodResults] = useState('');
  const [Metal, setMetal] = useState('');
  const [Finger, setFinger] = useState('');
  const [OtherName, setOtherName] = useState('');
  const [TimeToWear, setTimeToWear] = useState('');
  const [Weight, setWeight] = useState('');
  const [Dob, setDob] = useState('');
  const [Tob, setTob] = useState('');
  const [Latitude, setLatitude] = useState('');
  const [Longitude, setLongitude] = useState('');
  const [countryCode, setcountryCode] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [Mantra, setMantra] = useState('');
  const [Methods, setMethods] = useState('');
  const [Planet, setPlanet] = useState('');
  const [Trigger, setTrigger] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const TokenData = localStorage.getItem('CustomerTokenCredentials');
  const ParsedTokenData = JSON.parse(TokenData);
  const Token = ParsedTokenData.Token;

  useEffect(() => {
    if (Trigger) {
      const synthesis = window.speechSynthesis;
      const customScript = "I am here to show you, your Reports, click on the given names to see your report";
      const utterance = new SpeechSynthesisUtterance(customScript);
      synthesis.speak(utterance);
      setTrigger(false);
    }

    const userData = localStorage.getItem("submittedData");

    const data = localStorage.getItem("CustomerTokenCredentials");
    if (!data) {
      Navigate('/customer_login');
    }

    ZeroIndexNameFilteration();

  }, [Index.value]);

  const reportdata = localStorage.getItem('PostData');
  const ParseData = JSON.parse(reportdata);

  const ZeroIndexNameFilteration = (index) => {
    const data = ParseData;
    console.log(Index.value);

    setuserName(data[Index.value].userName);
    setGemsName(data[Index.value].gemsName);
    setDay(data[Index.value].day);
    setDescription(data[Index.value].description);
    setSubstitute(data[Index.value].substitute);
    setGoodResults(data[Index.value].GoodResults);
    setMetal(data[Index.value].Metal);
    setFinger(data[Index.value].Finger);
    setTimeToWear(data[Index.value].TimeToWear);
    setOtherName(data[Index.value].OtherName);
    setWeight(data[Index.value].Weight);
    setMantra(data[Index.value].Mantra);
    setMethods(data[Index.value].Methods);
    setAscendantSign(data[Index.value].ascendantSign);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const goHome = () => {
    Navigate('/');
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ParseData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <IoArrowBackCircle className='back_icon' style={{ cursor: "pointer" }} onClick={() => { window.history.back(); }} />
      <IoReload className='reload_icon' onClick={reloadPage} />
      <IoHome className='home_icons' onClick={goHome} />
      <div className='ReportPage'>
        <div className='ReportPage_sideBar'>
          <h4 className='Astroguru_report_headings'> Astro guru</h4>
          <div>
            <ol>
              {currentItems.map((gemstone, index) => (
                <div key={index}>
                  <p className='user_link' style={{ marginTop: "10px", fontSize: "13px" }} onClick={() => {
                    setIndex({ "value": indexOfFirstItem + index });
                    console.log(index);
                    const synthesis = window.speechSynthesis;
                    const customScript = `This is the Report Of ${gemstone.userName}`;
                    const utterance = new SpeechSynthesisUtterance(customScript);
                    synthesis.speak(utterance);
                  }}>
                    {gemstone.userName}
                  </p>
                </div>
              ))}
            </ol>
          </div>
          <Pagination
            totalItems={ParseData.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
        <main>
          <div className="book">
            <div className="book-cover">
              <div>
                <h1 className='book-cover-h1'>{userName}</h1>
                <div className="separator"></div>
                <h2 className='book-cover-h2'>{ascendantSign}</h2>
              </div>
            </div>
            <div className="book-content">
              <p className='book-content-p'>Your Lucky Gemstone is {gemsName}</p>
              <p className='book-content-p'> Your Ascendant Sign is {ascendantSign}</p>
              <div className='book-content-inner'>
                <p className='p'> Mantra:{Mantra}</p>
                <p className='p description'> Description: {description}</p>
                <p className='p'> Benefits To wear:{GoodResults}</p>
                <p className='p'> Day To wear:{day}</p>
                <p className='p'> Time To wear:{TimeToWear}</p>
                <p className='p'> Metal:{Metal}</p>
                <p className='p'> Weight:{Weight}</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ReportPage;
