import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FirstPage } from './Pages/FirstPage/FirstPage';
import DataPage from './Pages/DataPage/DataPage';
import './App.css'
import Datavalidation from './datavalidation/Datavalidation';
import GemstoneValidate from './GemstoneValidate/GemstoneValidate';
import BackgroundMusic from "./Assets/music.mp3"
import LoginPage from './Pages/LoginPage/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import PaymentForm from './Pages/PaymentPage/PaymentPage';
import VideoLayout from './VideoLayout';
import { FaVolumeMute } from "react-icons/fa";
import { AiFillSound } from "react-icons/ai";
import OTP from './Pages/LoginPage/OTP';
import YourResult from './Pages/ShowData/YourResult';

import NewReport from './Pages/DataPage/NewReport';

import Datastoring from './datavalidation/datastoring';
import ReportPage from './ReportsPage/ReportPage';

import SignIn from './Pages/CustomerLogin/SignIn';




function App() {
  const [audio] = useState(new Audio(BackgroundMusic));
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {



    
    // Function to start audio playback
    const startPlayback = () => {
      audio.removeAttribute('controls');
      
      audio.play().catch(error => {
        console.error('Failed to play audio:', error);
      });

      // Remove event listener after playback starts
      window.removeEventListener('click', startPlayback);
    };

    // Event listener for user interaction (click anywhere on the window)
    window.addEventListener('click', startPlayback);

    // Cleanup function to pause audio when component unmounts
    return () => {
      audio.pause();
    };
  });

  // Function to toggle mute/unmute
  const toggleMute = () => {
    audio.muted = !audio.muted;
    setIsMuted(audio.muted);
  };
 
  return (
    <>
   
      <Router>
        <Routes>
         
          <Route element={<ProtectedRoute/>}>
            <Route path="/" element={<FirstPage/>}/>
            <Route path="/showData" element={<YourResult/>}/>
            <Route path="/data" element={<DataPage/>}/>
            <Route path="/dataValidate" element={<VideoLayout><Datavalidation /></VideoLayout>}/>
            <Route path="/payment" element={<VideoLayout><PaymentForm/></VideoLayout>} />
            <Route path="/gemstoneValidate" element={<VideoLayout><GemstoneValidate /></VideoLayout>} />
            <Route path="/report_creation" element={<NewReport/>}/>
            <Route path="/masterdata_storing" element={<VideoLayout><Datastoring/></VideoLayout>}/>
            <Route path="/all_reports" element={<ReportPage/>}/>
            {/*<Route path="/admin" element={<AdminPage/>}/>*/}

          </Route>
          <Route path="/login" element={<LoginPage />} /> 
          
          <Route path="/customer_login" element={ <SignIn/>} />
          <Route path="/otp-validation" element={<OTP/>} />
        
        </Routes>
      </Router>

      {/* Sound control buttons */}
      <div style={{ position: 'fixed', top:15, right: 70 , color:'#ffffff'}}>
        {isMuted ? (
          <FaVolumeMute size={30} onClick={toggleMute} style={{ cursor: 'pointer' }} />
        ) : (
          <AiFillSound size={30} onClick={toggleMute} style={{ cursor: 'pointer' }} />
        )}
      </div>
    </>
  );
}

export default App;
