import React, { useState, useEffect } from 'react';
import { Menuicons } from '../Menuicons';
import { Loading } from '../../Loading';
import Logo from '../../Logo';

const PaymentComponent = () => {
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      const storeID = localStorage.getItem('storeID');
      console.log(storeID);

      try {
        const response = await fetch('https://myluckygems-1217ad5b1e42.herokuapp.com/pay', {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          },
         
        });
const url=response.url
console.log(url)
        if (response) {
          setIsLoading(false);
          
          
         window.location.href=response.url; // Store the URL in state
           // Open the modal
        } else {
         
        
          console.error('Error making payment:', response.statusText);
        }
      } catch (error) {
       
        console.error('Error making payment:', error);
      }
    };

    fetchData();

    // Clean up function
    return () => {
      // Optional cleanup code here
    };
  }, []);

 
  return (
   <>
   <Logo/>
   <Menuicons/>
   {isLoading?<Loading/>:<></>}
   </>
  );
};

export default PaymentComponent;
